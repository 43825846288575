/* Updated Header Styling with Global Colors and Fonts */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;
  background-color: var(--primary-color);
  color: var(--text-color);
  font-family: var(--font-primary);
}

/* Update header-right to ensure it stays on the right */
.header-right {
  display: flex;
  align-items: center;
  margin-left: auto;
  /* This is the key addition */
  gap: 16px;
}

.header-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.header-info {
  display: flex;
  align-items: center;
}

.title {
  font-size: 24px;
  color: var(--secondary-color);
    /* Secondary color for title text */
    font-family: var(--font-secondary);
    /* Use secondary font for title */
  text-decoration: none;
  margin-left: 20px;
  align-items: center;
}

.logo {
  height: 50px;
  width: 50px;
  border: 1px solid var(--accent-color);
    /* Accent color for logo border */
  border-radius: 50%;
  align-items: center;
}

nav ul {
  display: flex;
  list-style: none;
  margin-left: 20px;
}

nav ul li a {
  color: var(--accent-color);
    /* Accent color for nav links */
  text-decoration: none;
  margin-left: 15px;
  font-size: 18px;
  font-family: var(--font-secondary);
    /* Secondary font for nav links */
}

nav ul li a:hover {
  color: var(--secondary-color);
    /* Secondary color on hover */
}

.authButtons {
  display: flex;
}

.auth-button {
  min-width: 100px;
  margin-left: 10px;
  padding: 10px 20px;
  background-color: var(--secondary-color);
    /* Secondary color for button */
  border: none;
  color: var(--primary-color);
    /* High contrast with button background */
  cursor: pointer;
  font-family: var(--font-primary);
    /* Primary font for buttons */
}

.auth-button:hover {
  background-color: var(--accent-color);
    /* Accent color on button hover */
}

/* User Menu */
#userMenu {
  position: relative;
}

.user-icon {
  width: 40px;
  height: 40px;
  background-color: var(--accent-color);
    /* Accent color for user icon */
    color: var(--primary-color);
    /* High contrast text */
  font-size: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user-dropdown {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: var(--background-color);
    /* Background color for dropdown */
    border: 1px solid var(--primary-color);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: 200px;
  color: var(--text-color);
    /* Use default text color */
}

.user-dropdown a,
#logoutBtn {
  padding: 10px;
  text-decoration: none;
  color: var(--text-color);
    /* Consistent text color */
  display: block;
  cursor: pointer;
  border-bottom: 1px solid var(--primary-color);
}

.user-dropdown a:hover,
#logoutBtn:hover {
  background-color: var(--accent-color);
    /* Highlight color on hover */
}

.hidden {
  display: none;
}

.header-details-wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
    /* Move Resources to the right */
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown {
  color: var(--accent-color);
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--background-color);
  min-width: 160px;
  padding: 0;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
    /* Increased z-index to ensure visibility */
}

.dropdown-container:hover .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  padding: 8px;
  color: var(--text-color);
  background-color: var(--primary-color);
}

.dropdown-container .dropdown-icon {
  color: var(--accent-color);
  margin-right: 8px;
}

.dropdown-container .arrow {
  color: var(--accent-color);
  margin-left: 4px;
}

/* Header adjustments for mobile */
@media (max-width: 600px) {
  header {
    display: flex;
      flex-direction: row;
      /* Keep items in a row */
      align-items: center;
      justify-content: space-between;
    padding: 10px;
    width: 100%;
    }
    
    .header-content {
    display: flex;
    flex-direction: row;
    /* Keep header items side by side */
      align-items: center;
      gap: 10px;
      /* Add spacing between items */
      width: 100%;
    justify-content: space-between;
  
    nav ul {
      display: flex;
      flex-direction: row;
    /* Arrange nav items in a row */
      align-items: center;
      gap: 10px;
    padding: 0;
    margin: 0;
  }

        .authButtons {
          display: flex;
      flex-direction: row;
    gap: 5px;
      overflow-x: auto;
    }
}
}
/* Mobile adjustments */
@media (max-width: 600px) {
  .header-right {
    position: relative;
    /* Ensure proper positioning context */
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 200px;
    /* Fixed width on mobile */
    max-width: calc(100vw - 40px);
    /* Prevent overflow, leaving 20px padding on each side */
    background-color: var(--background-color);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .dropdown-menu li {
    width: 100%;
    /* Ensure list items take full width */
    padding: 12px 15px;
    /* Slightly larger touch targets */
  }

  .dropdown-menu li a {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    white-space: nowrap;
    /* Prevent text wrapping */
  }
}

/* Ensure dropdown stays visible when clicked on mobile */
@media (max-width: 600px) {
  .dropdown-container.active .dropdown-menu {
    display: block;
    position: absolute;
    z-index: 1000;
  }
}