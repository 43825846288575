:root {
  /* Color Palette */
  --primary-color: #1E2A38;
  --secondary-color: #FF6B6B;
  --accent-color: #4ECDC4;
  --background-color: #F7F9FC;
  --text-color: #333333;

  /* Typography */
  --font-primary: 'Poppins', sans-serif;
  --font-secondary: 'Montserrat', sans-serif;
  --font-size-base: 16px;
  --line-height-base: 1.5;
  
}

/* Global Typography Styles */
body {
  font-family: var(--font-primary);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  color: var(--text-color);
  background-color: var(--background-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-secondary);
}

/* Global CTA Button Styles */
.Global-CTA,
.Secondary-CTA {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 1rem;
  font-family: var(--font-primary);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
    font-weight: 500;
}

/* Fill style for primary actions */
.Global-CTA.fill {
  background: linear-gradient(90deg, var(--primary-color), var(--accent-color));
  color: var(--background-color);
  border: none;
}
.Secondary-CTA.fill {
  background: linear-gradient(90deg, var(--secondary-color), var(--accent-color));
  color: var(--background-color);
  border: none;
}
/* Outline style for secondary actions */
.Global-CTA.outline {
  color: #013532;
  border: 2px solid #007a72;
  background: transparent;
}

/* Hover effects for buttons */
.Global-CTA:hover,
.Secondary-CTA:hover {
  transform: translateY(-3px);
  box-shadow: 0px 4px 10px rgba(94, 1, 1, 0.1);
}
.Secondary-CTA:hover {
  background-color: #599cf4;
}


/* Responsive styles for mobile devices */
@media (max-width: 768px) {

  .Global-CTA,
  .Secondary-CTA {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {

  .Global-CTA,
  .Secondary-CTA {
    padding: 6px 12px;
    font-size: 0.8rem;
  }
}